// import logo from './logo.svg';
import logo from './sivix-v1-transparent.png';

import './App.css';
import { Dropdown, NextUIProvider, Spacer } from '@nextui-org/react';
import { Button, Image, Container, Grid  } from '@nextui-org/react';
import { createTheme, Text } from "@nextui-org/react"

// 2. Call `createTheme` and pass your custom values
const theme = createTheme({
  type: "dark", // it could be "light" or "dark"
  theme: {
    colors: {
      // brand colors
      primaryLight: '$green200',
      primaryLightHover: '$green300',
      primaryLightActive: '$green400',
      primaryLightContrast: '$green600',
      primary: '#4ADE7B',
      primaryBorder: '$green500',
      primaryBorderHover: '$green600',
      primarySolidHover: '$green700',
      primarySolidContrast: '$white',
      primaryShadow: '$green500',

      gradient: 'linear-gradient(112deg, $blue400 -10%, $purple400 -10%, $red500 90%)',
      link: '#5E1DAD',

      // you can also create your own color
      myColor: '#ff4ecd'

      // ...  more colors
    },
    space: {},
    fonts: {}
  }
});

function App() {
  return (
    <NextUIProvider theme={theme}>
      <Grid.Container justify="center" alignItems='center' >
        <Grid xs={2} justify='flex-start'>
          <Image
            src={logo}
            alt="Default Image"
            width={75}
            height={75}
          />
        </Grid>
        <Grid xs={10} justify='flex-end'>
        <Button.Group>
          <Button light>
            About
          </Button>
          <Button light>
            Plans
          </Button>
          <Button light>
            Blog
          </Button>
          </Button.Group>
          <Button rounded ghost color="gradient">
            Download
          </Button>
          <Spacer x={1}/>
          {/* <Dropdown>
          <Dropdown.Button flat>Trigger</Dropdown.Button>
      <Dropdown.Menu aria-label="Static Actions">
        <Dropdown.Item key="new">New file</Dropdown.Item>
        <Dropdown.Item key="copy">Copy link</Dropdown.Item>
        <Dropdown.Item key="edit">Edit file</Dropdown.Item>
        <Dropdown.Item key="delete" color="error">
          Delete file
        </Dropdown.Item>
      </Dropdown.Menu>
          </Dropdown> */}
        </Grid>
      </Grid.Container>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hello World!
          </a>
          <Button>Click Me!</Button>
        </header>
      </div>
    </NextUIProvider>
  );
}

export default App;
